import { iconHoveredMapping, iconMapping } from "../../../constants/icons";
import { HeaderDataType } from "../../../constants/types";
import { isExternalLink } from "../../../utils/common";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Link,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  data: HeaderDataType;
  onClose: () => void;
};

const Sidebar = ({ isOpen, data, onClose }: Props) => {
  const { navItems, socials } = data;

  const networks = navItems.find((item) => item.option_id === "networks");

  const filteredNavItems = navItems.filter(
    (item) => item.option_id !== "networks"
  );

  return (
    <Drawer
      autoFocus={false}
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bg="#142528" overflow="auto">
        <DrawerBody py={3} px={4} color="white">
          <HStack justify="space-between">
            <Flex align="center">
              <Image
                src="/assets/images/stader-icon.svg"
                alt="stader-icon"
                boxSize={8}
              />
              <Text fontSize={22} fontWeight={600}>
                Stader
              </Text>
            </Flex>
            <HStack align="center" gap={2}>
              {socials.map(({ imgKey, link }) => (
                <Link boxShadow="none" key={imgKey} href={link} isExternal>
                  <Image boxSize={6} src={iconMapping[imgKey]} alt={imgKey} />
                </Link>
              ))}
            </HStack>
          </HStack>
          <VStack gap={4} align="flex-start" mt={10} pl={10}>
            {networks?.details.map(({ label, imgKey, link }) => (
              <HStack as="a" href={link} key={label} gap={2} align="center">
                <Image
                  boxSize={6}
                  src={iconHoveredMapping[imgKey]}
                  alt={label}
                />
                <Text textStyle="p14" fontWeight={600}>
                  {label}
                </Text>
              </HStack>
            ))}
            <Accordion w="full" allowToggle>
              {filteredNavItems.map((item) => {
                const hasMultipleEntries = item.details.length > 1;

                if (hasMultipleEntries) {
                  return (
                    <AccordionItem
                      key={`accordion-${item.option_id}`}
                      border="1px solid"
                      borderColor="#424242"
                      borderX="none"
                    >
                      <AccordionButton px={0} py={4}>
                        <Box as="span" flex="1" textAlign="left">
                          {item.title}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <VStack align="flex-start">
                          {item.details.map(({ id, label, link, tag }) => (
                            <Text
                              display="block"
                              as="a"
                              href={link}
                              target={isExternalLink(link) ? "_blank" : "self"}
                              key={id}
                            >
                              {label}{" "}
                              {tag && (
                                <Tag
                                  ml={2}
                                  textStyle="l10"
                                  className="tag"
                                  bg="notification"
                                  py={0.5}
                                  px={2}
                                  textTransform="uppercase"
                                  color="white"
                                >
                                  <Text textStyle="l12">{tag}</Text>
                                </Tag>
                              )}
                            </Text>
                          ))}
                        </VStack>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                }

                return (
                  <Text
                    key={`label-${item.option_id}`}
                    py={4}
                    display="block"
                    as="a"
                    rel="canonical"
                    href={item.details[0].link}
                    target={
                      isExternalLink(item.details[0].link) ? "_blank" : "self"
                    }
                  >
                    {item.title}
                  </Text>
                );
              })}
            </Accordion>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;
