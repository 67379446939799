type Mapping = {
  [x: string]: any;
};

export const iconMapping: Mapping = {
  stader: "/assets/images/stader-icon.svg",
  polygon: "/assets/images/networks/polygon.svg",
  bnb: "/assets/images/networks/binance.svg",
  hedera: "/assets/images/networks/hedera.svg",
  near: "/assets/images/networks/near.svg",
  terra: "/assets/images/networks/terra.svg",
  fantom: "/assets/images/networks/fantom.svg",
  eth: "/assets/images/networks/ethereum.svg",
  telegram: "/assets/images/social/telegram.svg",
  twitter: "/assets/images/social/twitter.svg",
  discord: "/assets/images/social/discord.svg",
  reddit: "/assets/images/social/reddit.svg",
  community: "/assets/images/community-forum.svg",
  snapshot: "/assets/images/snapshot.svg",
  sd: "/assets/images/sd.svg",
  github: "/assets/images/github.svg",
  litepaper: "/assets/images/litepaper.svg",
  docs: "/assets/images/docs.svg",
  blogs: "/assets/images/blogs.svg",
  download: "/assets/images/download.svg",
  pantera: "/assets/images/partners/institutions/pantera.webp",
  coinbase: "/assets/images/partners/institutions/coinbase-ventures.webp",
  jump: "/assets/images/partners/institutions/jump-crypto.webp",
  blockchain: "/assets/images/partners/institutions/blockchain-com.webp",
  true: "/assets/images/partners/institutions/true-ventures.webp",
  ledger: "/assets/images/partners/ecosystems/ledger.webp",
  anchorage: "/assets/images/partners/ecosystems/anchorage.webp",
  bitgo: "/assets/images/partners/ecosystems/bitgo.webp",
  aave: "/assets/images/partners/ecosystems/aave.webp",
  balancer: "/assets/images/partners/ecosystems/balancer.webp",
  beefy: "/assets/images/partners/ecosystems/beefy.webp",
  swissborg: "/assets/images/partners/ecosystems/swissborg.webp",
  quickswap: "/assets/images/partners/ecosystems/quickswap.webp",
  okx: "/assets/images/partners/exchanges/okx.svg",
  gate: "/assets/images/partners/exchanges/gate-io.webp",
  huobi: "/assets/images/partners/exchanges/huobi.svg",
  bybit: "/assets/images/partners/exchanges/bybit.webp",
  kucoin: "/assets/images/partners/exchanges/kucoin.webp",
  crypto: "/assets/images/partners/exchanges/crypto-com.webp",
};

export const iconHoveredMapping: Mapping = {
  stader: "/assets/images/stader-icon-white.svg",
  polygon: "/assets/images/networks/polygon-white.svg",
  bnb: "/assets/images/networks/binance-white.svg",
  hedera: "/assets/images/networks/hedera-white.svg",
  near: "/assets/images/networks/near-white.svg",
  terra: "/assets/images/networks/terra-white.svg",
  fantom: "/assets/images/networks/fantom-white.svg",
  eth: "/assets/images/networks/ethereum.svg",
  telegram: "/assets/images/social/telegram.svg",
  twitter: "/assets/images/social/twitter.svg",
  discord: "/assets/images/social/discord.svg",
  community: "/assets/images/community-forum.svg",
  snapshot: "/assets/images/snapshot.svg",
  sd: "/assets/images/sd.svg",
  github: "/assets/images/github-white.svg",
  litepaper: "/assets/images/litepaper-white.svg",
  docs: "/assets/images/docs-white.svg",
  blogs: "/assets/images/blogs-white.svg",
  download: "/assets/images/download-white.svg",
};
