import { Box, HStack, Link, Tag, Text } from "@chakra-ui/react";
import { EVENT_MAPPINGS } from "../../../constants/firebase";
import { trackEvent } from "../../../utils/firebase";
import { isExternalLink } from "../../../utils/common";
import useOriginURL from "../../../hooks/useOriginURL";
import NextLink from "next/link";

type Props = {
  label: string;
  items: Array<{ label: string; detailId: string; link: string; tag?: string }>;
};

const Column = ({ label: title, items }: Props) => {
  const originURL = useOriginURL();

  const isSPALink = (link: string | null) => {
    if (isExternalLink(link)) {
      return false;
    } else if (originURL) {
      return false;
    }
    return true;
  };

  const getLink = (link: string | null) => {
    if (link && isExternalLink(link)) {
      return link;
    }
    if (link) {
      return originURL + link;
    }

    return "";
  };

  return (
    <Box textAlign="left" w={244}>
      <Text
        fontSize={{ base: 20, lg: 22.5 }}
        fontWeight={{ base: 600, lg: 800 }}
        mb={{ base: 3, lg: 4 }}
      >
        {title}
      </Text>
      {items.map(({ detailId, label, link, tag }, index: number) => (
        <HStack
          key={`${detailId}-${index}`}
          align="center"
          gap={3}
          opacity={0.7}
        >
          <Link
            as={isSPALink(link) ? NextLink : Link}
            href={getLink(link)}
            rel={title === "Networks" ? "canonical" : "noopener noreferrer"}
            target={isExternalLink(link) ? "_blank" : "_self"}
            legacyBehavior={false}
          >
            <Text
              cursor="pointer"
              color="#FFFFFF"
              fontWeight="400"
              fontSize={{ base: 12, lg: 16 }}
              py={1}
              display="block"
              onClick={() => {
                trackEvent(EVENT_MAPPINGS[title.toLowerCase()] || title, {
                  cta: "footer",
                  name: label,
                });
              }}
            >
              {label}
            </Text>
          </Link>
          {tag && (
            <Tag
              className="tag"
              bg="notification"
              py={1}
              px={2}
              textTransform="uppercase"
              color="white"
            >
              <Text textStyle={{ base: "l10", lg: "l12" }}>{tag}</Text>
            </Tag>
          )}
        </HStack>
      ))}
    </Box>
  );
};

export default Column;
