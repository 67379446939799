import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Text,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import NextLink from "next/link";
import { BlockChainInfoType } from "../../hooks/useStaderTVL";

// Components
import Banner from "../Banner";
import Sidebar from "./components/Sidebar";
import HeaderDrawer from "./components/HeaderDrawer";
import StakeNow from "./components/StakeNow";

import { HeaderDataType, NavItemsType } from "../../constants/types";
import { iconMapping } from "../../constants/icons";
import {
  EVENT_MAPPINGS,
  LP_CLOSE_BANNER,
  LP_SOCIAL,
} from "../../constants/firebase";

import { createCookieName } from "../../utils/common";
import { trackEvent } from "../../utils/firebase";
import useOriginURL from "../../hooks/useOriginURL";

type HeaderProps = {
  data: HeaderDataType;
  banner?: {
    text: string;
    ctaText: string;
    ctaLink: string;
  };
  networks: NavItemsType["details"];
  bgColor?: string;
  tvl: BlockChainInfoType | undefined;
  isCTAvisible?: boolean;
};

const Header = ({
  data,
  banner,
  networks,
  bgColor,
  tvl,
  isCTAvisible = true,
}: HeaderProps) => {
  const { navItems, socials } = data;
  const { text, ctaLink, ctaText } = banner || {};
  const cookieName = createCookieName(text || "");
  const [activeHeader, setActiveHeader] = useState<NavItemsType | undefined>();
  const [cookies, setCookie] = useCookies([cookieName]);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const originURL = useOriginURL();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeHeader = () => setActiveHeader(undefined);

  const closeBanner = () => {
    setCookie(cookieName, false, {
      maxAge: 1209600,
    });
    trackEvent(LP_CLOSE_BANNER);
    setShowBanner(false);
  };

  useEffect(() => {
    if (banner) {
      if (cookieName in cookies && !cookies[cookieName]) {
        setShowBanner(false);
      } else if (!(cookieName in cookies)) {
        setShowBanner(true);
      }
    }
  }, []);

  const router = useRouter();

  return (
    <>
      {router.pathname === "/" && text ? (
        <Banner
          open={showBanner}
          close={closeBanner}
          text={text || ""}
          ctaText={ctaText || ""}
          ctaLink={ctaLink || ""}
        />
      ) : null}
      <Box
        w={{ base: "100vw", lg: "full" }}
        zIndex={activeHeader ? 1401 : 10}
        bg={bgColor ? bgColor : "white"}
        pos="sticky"
        top={0}
      >
        <Container p={{ base: 3, lg: "24px 16px 20px" }}>
          <HStack justify="space-between">
            <Flex align="center" ml={{ base: 0, lg: -2 }}>
              <Image
                display={{ base: "inherit", lg: "none" }}
                src="/assets/images/hamburger.svg"
                alt="hamburger-icon"
                boxSize={8}
                onClick={onOpen}
                cursor="pointer"
                mr={2}
              />
              <Link
                color="textPrimary"
                _dark={{
                  color: "textPrimary",
                }}
                as={originURL ? Link : NextLink}
                href={`${originURL}/`}
                legacyBehavior={false}
              >
                <Flex align="center" cursor="pointer">
                  <Image
                    src={iconMapping[data.icon_key]}
                    alt="stader-icon"
                    boxSize={{ base: 8, lg: 10 }}
                  />
                  <Text fontSize={{ base: 22, lg: 28 }} fontWeight={600}>
                    {data.brand}
                  </Text>
                </Flex>
              </Link>
            </Flex>
            <Flex gap={6} display={{ base: "none", lg: "inherit" }}>
              {navItems.map((item) => {
                const isActive = activeHeader?.option_id === item.option_id;

                const hasMultipleEntries = item.details.length > 1;
                const link = item.details[0].link;

                return (
                  <Flex
                    key={`header-${item.option_id}`}
                    align="center"
                    color={isActive ? "accent" : "inherit"}
                    cursor="pointer"
                    fontWeight={600}
                    onClick={() => {
                      if (!hasMultipleEntries) {
                        window.open(link, "_blank");
                        trackEvent(
                          EVENT_MAPPINGS[item.title.toLowerCase()] ||
                            item.title,
                          {
                            cta: "header",
                            name: item.title,
                          }
                        );
                      }
                    }}
                    onMouseEnter={() => {
                      closeHeader();
                      hasMultipleEntries && setActiveHeader(item);
                    }}
                    onMouseLeave={() => !hasMultipleEntries && closeHeader()}
                  >
                    <Text>{item.title}</Text>
                    {hasMultipleEntries && (
                      <Image
                        boxSize="16px"
                        src={
                          isActive
                            ? "/assets/images/chevron-up-active.svg"
                            : "/assets/images/chevron-down.svg"
                        }
                        alt="chevron"
                      />
                    )}
                  </Flex>
                );
              })}
            </Flex>
            <HStack
              align="center"
              gap={4}
              display={{ base: "none", lg: "inherit" }}
            >
              <HStack align="center" gap="8px">
                {socials.map(({ imgKey, link }) => (
                  <Link
                    key={link}
                    href={link}
                    isExternal
                    borderRadius="50%"
                    onClick={() =>
                      trackEvent(LP_SOCIAL, {
                        social_cta: "header",
                        social_name: imgKey,
                      })
                    }
                    border="1px solid"
                    borderColor="white"
                    _hover={{
                      borderColor: "accent",
                    }}
                  >
                    <Image
                      src={iconMapping[imgKey]}
                      alt={imgKey}
                      boxSize="40px"
                    />
                  </Link>
                ))}
              </HStack>
              {isCTAvisible && (
                <StakeNow buttonText={data.ctaText} networks={networks} />
              )}
            </HStack>
          </HStack>
        </Container>
        <Sidebar isOpen={isOpen} data={data} onClose={onClose} />
        <Drawer isOpen={!!activeHeader} placement="top" onClose={closeHeader}>
          <DrawerOverlay />
          <DrawerContent
            bg="white"
            pt={showBanner ? "102px" : "74px"}
            onMouseLeave={(e) => {
              if (e.clientY > (showBanner ? 102 : 74)) {
                closeHeader();
              }
            }}
          >
            <DrawerBody py={0}>
              {activeHeader && <HeaderDrawer data={activeHeader} tvl={tvl} />}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};

export default Header;
