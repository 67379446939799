/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Head from "next/head";
import { useEffect, useMemo, useState } from "react";

import { Box, Flex, Text, Image } from "@chakra-ui/react";
import Footer from "../../Footer";
import Header from "../../Header";
import Newsletter from "../../Newsletter";
import { useStaderTVL } from "../../../hooks/useStaderTVL";
import {
  getSectionDataByKey,
  prepareNavData,
  trimText,
} from "../../../utils/common";
import { socialImageMapping } from "../../../constants/images";
import { blogTagMapping, tagLabelMap } from "../../../constants/blogs";
import Blogs from "../Blogs";
import { getRelatedBlogs } from "../../../services/app";
import BreadCrum from "../../BreadCrum/BreadCrumV2";
import { pathToRobots } from "../seoData";
import useOriginURL from "../../../hooks/useOriginURL";

const subRoute = process.env.NEXT_PUBLIC_SUB_ROUTE || "";

const blogsData = {
  section_details: {
    title: "Related Articles",
    section_id: "related_articles",
  },
};
interface PostProps {
  tagPage?: boolean;
  page?: any;
}
const Post = ({ page, tagPage }: PostProps) => {
  const originURL = useOriginURL();

  const [isBannerScrolled, setIsBannerScrolled] = useState(false);
  const pageData = useMemo(() => {
    return page ? page.data?.pageData : null;
  }, [page]);
  const postData = useMemo(() => {
    return page ? page?.data?.postData : null;
  }, [page]);
  const [isValidPage, setIsValidPage] = useState(false);
  const subscriptionData = getSectionDataByKey(pageData, "subscription");
  const { tvl } = useStaderTVL();
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [h2Tags, setH2Tags] = useState<string[]>([]);

  const { navData, networks } = useMemo(
    () => prepareNavData(page?.data?.navData, tvl),
    [page?.data?.navData, tvl]
  );

  const postTags =
    postData?.posts[0]?.tags
      .map((tag: any) => tag.slug)
      .filter((tag: string) => Object.values(blogTagMapping).includes(tag)) ||
    [];

  // const postScripts = postData?.posts[0]?.codeinjection_head
  //   ? JSON.parse(postData?.posts[0].codeinjection_head)
  //   : [];

  const fetchRelatedBlogs = async () => {
    const res = await getRelatedBlogs(postTags);
    if (res?.data?.posts) {
      let filteredPosts = res.data.posts.filter(
        (item: any) => item.slug !== postData?.posts[0].slug
      );
      if (filteredPosts.length > 3) {
        filteredPosts.length = 3;
      }
      setRelatedPosts(filteredPosts);
    }
  };

  useEffect(() => {
    fetchRelatedBlogs();
  }, [postData]);

  useEffect(() => {
    if (isValidPage) {
      iframeLoaded();
    }
  }, [isValidPage, postData]);

  const handleIframeLink = (link: string) => {
    window.open(link, "_self");
  };

  const iframeLoaded = () => {
    const iFrameID: any = document.getElementById("idIframe");
    if (iFrameID) {
      const div = document.createElement("div");
      div.innerHTML = postData?.posts[0].html;

      const aTags = div.getElementsByTagName("a");
      for (let i = 0; i < aTags.length; i++) {
        aTags[i].style.color = "#07A658";
        aTags[i].style.textDecoration = "none";
        aTags[i].style.fontWeight = "400";
        aTags[i].rel = "canonical";
        aTags[i].addEventListener("click", function (event) {
          handleIframeLink(aTags[i].href);
          event.preventDefault();
        });
      }

      const imgTags = div.getElementsByTagName("img");
      for (let i = 0; i < imgTags.length; i++) {
        imgTags[i].style.width = "100%";
        imgTags[i].style.height = "auto";
      }

      let h2Text: string[] = [];
      const h2Tags = div.getElementsByTagName("h2");
      for (let i = 0; i < h2Tags.length; i++) {
        h2Text.push(h2Tags[i].innerText);
      }
      setH2Tags(h2Text);

      iFrameID.contentWindow.document.body.style.lineHeight = "1.5";
      iFrameID.contentWindow.document.body.style.textAlign = "justify";
      iFrameID.contentWindow.document.body.style.fontSize = "15px";
      iFrameID.contentWindow.document.body.style.color = "#3f6870";

      iFrameID.contentWindow.document.body.appendChild(div);
      iFrameID.contentWindow.document.body.style.margin = "0px";
      iFrameID.contentWindow.document.body.style.overflowY = "hidden";
      iFrameID.contentWindow.document.body.style.fontFamily =
        "'Gilroy', sans-serif";
      iFrameID.height = "";
      iFrameID.height =
        iFrameID.contentWindow.document.body.scrollHeight + "px";
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  };

  useEffect(() => {
    const heroSection = document.getElementById("blogHeroSection");
    const observer = new IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            setIsBannerScrolled(true);
          } else {
            setIsBannerScrolled(false);
          }
        }
      },
      {
        threshold: 0.9,
      }
    );
    heroSection && observer.observe(heroSection);
    setIsValidPage(true);
    // cleanup function
    return () => {
      heroSection && observer.unobserve(heroSection);
    };
  }, []);

  const getSocialURL = (name: string, data: any) => {
    switch (name) {
      case "twitter":
        return `https://twitter.com/intent/tweet?text=${data.title}&url=https://www.staderlabs.com/blogs/${data.slug}/&hashtags=&via=staderlabs`;
      case "telegram":
        return `https://t.me/share/url?url=https://www.staderlabs.com/blogs/${data.slug}/&text=${data.title}`;
      case "whatsapp":
        return `https://api.whatsapp.com/send?text=${data.title}https://www.staderlabs.com/blogs/${data.slug}`;
      case "linkedin":
        return `http://www.linkedin.com/shareArticle?mini=true&url=https://www.staderlabs.com/blogs/${data.slug}/&title=${data.title}&source=https://www.staderlabs.com/blogs`;
      case "facebook":
        return `https://www.facebook.com/sharer/sharer.php?u=https://www.staderlabs.com/blogs/${data.slug};src=https://www.staderlabs.com/blogs`;
    }
  };

  function convertStringToText(htmlString: any) {
    if (htmlString) {
      const text = htmlString.replace(/<[^>]+>/g, "");
      const words = text.split(/\s+/);
      const strippedWords = words.slice(0, 25);
      const strippedString = strippedWords.join(" ");
      return strippedString;
    }
    return "Thoughts, stories and ideas.";
  }

  const canonicalURL = useMemo(() => {
    const tag = postData?.posts[0]?.tags[0]?.slug;
    if (tag) {
      return `https://www.staderlabs.com/blogs/${tag}/${postData?.posts[0].slug}/`;
    }
    return `https://www.staderlabs.com/blogs/${postData?.posts[0].slug}/`;
  }, [postData]);

  const breadCrumData = useMemo(() => {
    let routes: any = [];
    const title = postData?.posts[0].title;
    const tag = postData?.posts[0].tags[0]?.slug;
    if (title && tagPage) {
      routes = [
        { label: "Home", path: `${originURL}/` },
        { label: "Blogs", path: `/` },
        // { label: "Our blogs", path: `/` },
        { label: trimText(postData?.posts[0].title), path: `/` },
      ];
    } else if (title && tag) {
      routes = [
        { label: "Home", path: `${originURL}/` },
        { label: "Blogs", path: `/` },
        {
          label: tagLabelMap[tag],
          path: `/?tag=${tag}`,
        },
        { label: trimText(postData?.posts[0].title), path: `/` },
      ];
    }
    return routes;
  }, [originURL, postData, tagPage]);

  if (page) {
    return (
      <>
        <Text as="h1" height={0} opacity={0}>
          {postData?.posts[0].title}
        </Text>
        {h2Tags.map((h2tag: string) => {
          return (
            <Text key={h2tag} as="h2" height={0} opacity={0}>
              {h2tag}
            </Text>
          );
        })}
        <Head>
          <title>
            {postData?.posts[0].meta_title ||
              postData?.posts[0].title ||
              "Stader blog"}
          </title>
          <meta
            name="description"
            content={
              postData?.posts[0].meta_description ||
              convertStringToText(postData?.posts[0].html)
            }
          />
          <link rel="canonical" href={canonicalURL} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.ico" />
          <meta
            name="robots"
            content={
              tagPage
                ? pathToRobots[postData?.posts[0].slug] || "index, follow"
                : "index, follow"
            }
          />
          {/* {postScripts?.length > 0 && (
            <>
              {postScripts.map((data: any, index: number) => {
                return (
                  <script
                    key={index}
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                      __html: JSON.stringify(data),
                    }}
                  />
                );
              })}
            </>
          )} */}
        </Head>
        <Header
          data={navData}
          networks={
            postTags.length === 0
              ? networks
              : networks.filter((network) => {
                  return postTags.includes(
                    blogTagMapping[network.dataKey as string]
                  );
                })
          }
          bgColor={isBannerScrolled ? "transparent" : undefined}
          tvl={tvl}
        />

        {postData &&
          postData.posts?.map((item: any, index: number) => {
            if (index === 0) {
              return (
                <Box
                  key={item.slug}
                  mt="32px"
                  w="100%"
                  maxW={{ base: "328px", lg: "826px" }}
                  overflow="hidden"
                >
                  <BreadCrum
                    m={0}
                    currentPath={trimText(item.title)}
                    isInternal={subRoute === ""}
                    data={breadCrumData}
                  />
                  <Image
                    src={item.feature_image}
                    alt="feature_image"
                    w={{ base: "328px", lg: "826px" }}
                    h={{ base: "184.65px", lg: "465px" }}
                    mt={{ base: "12px", lg: "16px" }}
                  />
                  <Text
                    fontWeight={700}
                    fontSize={{ base: "24px", lg: "48px" }}
                    mt="64px"
                    lineHeight={{ base: "28px", lg: "56px" }}
                  >
                    {item.title}
                  </Text>
                  <Flex
                    mt={{ base: "24px", lg: "40px" }}
                    flexDirection={{ base: "column", lg: "row" }}
                    justifyContent="space-between"
                    alignItems={{ base: "start", lg: "center" }}
                  >
                    <Text
                      color="#3F6870"
                      fontSize={{ base: "14px", lg: "20px" }}
                      fontWeight={600}
                    >
                      {item.reading_time} mins read / updated on{" "}
                      {new Date(item.updated_at).toDateString()}
                    </Text>
                    <Flex
                      gap={{ base: "12px", lg: "16px" }}
                      mt={{ base: "12px", lg: 0 }}
                    >
                      {Object.keys(socialImageMapping).map((name) => {
                        return (
                          <Box
                            key={name}
                            as="a"
                            href={getSocialURL(name, item)}
                            target="_blank"
                            cursor="pointer"
                          >
                            <Image src={socialImageMapping[name]} alt={name} />
                          </Box>
                        );
                      })}
                    </Flex>
                  </Flex>
                  <Box mt="64px">
                    {isValidPage ? (
                      <iframe style={{ width: "100%" }} id="idIframe" />
                    ) : (
                      <Box
                        lineHeight="1.5"
                        textAlign="justify"
                        fontSize="15px"
                        color="#3f6870"
                        dangerouslySetInnerHTML={{
                          __html: postData?.posts[0].html,
                        }}
                      />
                    )}
                  </Box>
                  <Box my={{ base: "48px", lg: "64px" }}>
                    <Text color="#3F6870" fontSize="12px" fontWeight={500}>
                      By:
                    </Text>
                    <Text
                      mt="8px"
                      color="#142528"
                      fontSize={{ base: "16px", lg: "20px" }}
                      fontWeight={{ base: 600, lg: 700 }}
                    >
                      {item.primary_author.name}
                    </Text>
                  </Box>
                </Box>
              );
            }
            return null;
          })}

        <Newsletter data={subscriptionData} networks={networks} />
        {relatedPosts.length > 0 && (
          <Blogs
            data={blogsData}
            relatedArticles={relatedPosts}
            pb={{ base: "64px", lg: "160px" }}
            pt="64px"
            isInternal
          />
        )}
        <Footer data={navData} isEmailModalRequired={false} />
      </>
    );
  }
  return null;
};

export default Post;
