import { ReactNode } from "react";
import { PageProvider as DappPageProvider } from "mlp-sdk";
import navData from "@constants/navData.json";
import pageData from "@constants/pageData.json";
interface IProps {
  children: ReactNode;
  page?: any;
}

export const PageProvider = ({ page, children }: IProps) => {
  const data = {
    data: { navData, pageData },
    error: null,
    ...page,
  };
  return <DappPageProvider page={data}>{children}</DappPageProvider>;
};
