import { useEffect, useState } from "react";
import { BoxProps, Box, Flex, Button, Show } from "@chakra-ui/react";

// Components
import Carousel from "./components/Carousel";
import Section from "../Section";
import BlogCard from "./components/BlogCardV2";

// Services
import { getBlogs } from "../../services/app";
import { trackEvent } from "../../utils/firebase";

import { LP_BLOGS_VIEW_MORE } from "../../constants/firebase";

type Props = {
  data: {
    section_details: {
      title: string;
      section_id: string;
      cta_text?: string;
      cta_link?: string;
    };
  };
  relatedArticles?: any;
  isInternal?: boolean;
};

const Blogs = ({
  data,
  isInternal,
  relatedArticles,
  ...props
}: Props & BoxProps) => {
  const {
    section_details: { title, section_id, cta_text, cta_link },
  } = data;

  const [blogs, setBlogs] = useState<Array<any>>([]);

  const fetchBlogs = async () => {
    const response = await getBlogs();

    setBlogs(response.data?.posts || []);
  };

  useEffect(() => {
    if (relatedArticles) {
      setBlogs(relatedArticles);
      return;
    } else {
      fetchBlogs();
    }
  }, [relatedArticles]);

  return (
    <Section title={title} id={section_id} {...props}>
      <Box px={{ base: 4, lg: 0 }}>
        <Show above="lg">
          <Flex
            mt={16}
            flexDir={{ base: "column", lg: "row" }}
            align={{ base: "center", lg: "center" }}
            justify="space-between"
            gap={4}
          >
            {blogs.map((item, index) => (
              <BlogCard
                data={item}
                key={`${item.slug}-${index}`}
                variant={2}
                isInternal={isInternal}
              />
            ))}
          </Flex>
        </Show>
        <Show below="lg">
          <Carousel blogs={blogs} isInternal={isInternal} />
        </Show>
        {cta_text && (
          <Button
            as="a"
            href={cta_link}
            mt={16}
            variant="outline"
            size="xl"
            onClick={() => {
              trackEvent(LP_BLOGS_VIEW_MORE);
            }}
          >
            {cta_text}
          </Button>
        )}
      </Box>
    </Section>
  );
};

export default Blogs;
