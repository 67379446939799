import React, { useEffect, useState } from "react";
import NextLink from "next/link";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";

// Components
import Column from "./components/Column";
import EmailModal from "./components/EmailModal";
import Section from "../Section";

// Constants
import { HeaderDataType } from "../../constants/types";
import { iconMapping } from "../../constants/icons";
import { LP_SOCIAL, LP_TOS_CLICK } from "../../constants/firebase";

import { trackEvent } from "../../utils/firebase";
import { isSubscribedToEmail } from "../../utils/common";
import useOriginURL from "../../hooks/useOriginURL";

type FooterProps = {
  data: HeaderDataType;
  isEmailModalRequired?: boolean;
};

const Footer = ({ data, isEmailModalRequired = true }: FooterProps) => {
  const { navItems, socials, brand, icon_key, copyrightText, footerLinks } =
    data;
  const [isUserSubscribed, setIsUserSubscribed] = useState(true);
  const originURL = useOriginURL();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsUserSubscribed(isSubscribedToEmail());
    }
  }, []);

  useEffect(() => {
    function checkIfUserSubscribed() {
      if (typeof window !== "undefined") {
        setIsUserSubscribed(isSubscribedToEmail());
        return;
      }
      if (!isUserSubscribed) {
        setIsUserSubscribed(false);
      }
    }

    window.addEventListener("storage", checkIfUserSubscribed);

    return () => {
      window.removeEventListener("storage", checkIfUserSubscribed);
    };
  }, []);

  return (
    <Box bg="#142528" w="full" color="white">
      <Section py={{ base: "40px", lg: "56px" }}>
        {!isUserSubscribed && isEmailModalRequired ? <EmailModal /> : null}
        <Flex
          w="full"
          mt={{ base: 0, lg: 12 }}
          justify="space-between"
          flexDir={{ base: "column", lg: "row" }}
          px={{ base: 4, lg: 0 }}
        >
          <Box>
            <Link
              color="#FFF"
              _dark={{
                color: "#FFF",
              }}
              as={originURL ? Link : NextLink}
              href={`${originURL}/`}
              legacyBehavior={false}
            >
              <Flex align="center" ml={{ base: 0, lg: -2 }} cursor="pointer">
                <Image
                  src={iconMapping[icon_key]}
                  alt="stader-icon"
                  boxSize={10}
                  ml={-2}
                />
                <Text fontSize={{ base: 22, lg: 28 }} fontWeight={600}>
                  {brand}
                </Text>
              </Flex>
            </Link>
            <HStack mt={6} align="center" gap={2}>
              {socials.map(({ imgKey, link }, index: number) => (
                <React.Fragment key={`${link}-${index}`}>
                  <Link
                    href={link}
                    rel="canonical"
                    isExternal
                    onClick={() =>
                      trackEvent(LP_SOCIAL, {
                        social_cta: "footer",
                        social_name: imgKey,
                      })
                    }
                    borderRadius="50%"
                    border="1px solid"
                    borderColor="#142528"
                    _hover={{
                      borderColor: "accent",
                    }}
                  >
                    <Image
                      boxSize={{ base: 8, lg: 10 }}
                      src={iconMapping[imgKey]}
                      alt={imgKey}
                    />
                  </Link>
                </React.Fragment>
              ))}
            </HStack>
          </Box>
          <Flex
            mt={{ base: 10, lg: 0 }}
            flexDir={{ base: "column", lg: "row" }}
            gap={10}
            flexWrap="wrap"
            w={{ base: "auto", lg: 812 }}
          >
            {navItems.map(({ title, details }, index: number) => (
              <Column
                key={`footer-${title}-${index}`}
                label={title}
                items={details}
              />
            ))}
          </Flex>
        </Flex>
        <Flex
          justify="space-between"
          flexDir={{ base: "column", lg: "row" }}
          align={{ base: "flex-start", lg: "center" }}
          pt={8}
          px={{ base: 4, lg: 0 }}
          fontSize={{ base: 14, lg: 16 }}
          gap={4}
          opacity={0.7}
          mb={!isUserSubscribed && isEmailModalRequired ? "1rem" : 0}
        >
          <Text>{copyrightText}</Text>
          <Flex gap="0.5rem" alignItems="center">
            {footerLinks.links.map((link, index) => (
              <React.Fragment key={`${link.key}-${index}`}>
                <Link
                  as={originURL ? Link : NextLink}
                  href={`${originURL}${link.link}`}
                  legacyBehavior={false}
                >
                  <Text
                    fontSize={{ base: 14, lg: 16 }}
                    color="#FFFFFF"
                    fontWeight="400"
                    cursor="pointer"
                    onClick={() => {
                      trackEvent(LP_TOS_CLICK, {
                        cta: link.title,
                        link: link,
                      });
                    }}
                  >
                    {link.title}
                  </Text>
                </Link>
                {index !== footerLinks.links.length - 1 && (
                  <Divider
                    orientation="vertical"
                    borderWidth="1px"
                    height={{ base: "12px", lg: "14px" }}
                  />
                )}
              </React.Fragment>
            ))}
          </Flex>
        </Flex>
      </Section>
    </Box>
  );
};

export default Footer;
