export enum ENVIRONMENT {
  PROD = "production",
  PREPROD = "pre-production",
  STAGE = "stage",
}

type URLMap = {
  [env in ENVIRONMENT]: string;
};

export const DEFAULT_UNIVERSE_URL: URLMap = {
  [ENVIRONMENT.PROD]: "https://universe.staderlabs.com",
  [ENVIRONMENT.PREPROD]: "https://beta.universe.staderlabs.com",
  [ENVIRONMENT.STAGE]: "https://stage.universe.staderlabs.com",
};
