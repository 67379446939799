import { useState } from "react";
import { Box, Center, HStack } from "@chakra-ui/react";
import { useSwipeable } from "react-swipeable";
import BlogCard from "./BlogCard";

type Props = {
  blogs: Array<{
    id: string;
    feature_image: string;
    title: string;
    reading_time: string;
    updated_at: string;
  }>;
  isInternal?: boolean;
};

const Carousel = ({ blogs, isInternal }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSwipeRight = () => {
    setCurrentIndex((prev) => {
      if (prev === blogs.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const handleSwipeLeft = () => {
    setCurrentIndex((prev) => {
      if (prev === 0) {
        return blogs.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeRight,
    onSwipedRight: handleSwipeLeft,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Box mt={10} textAlign="center" {...handlers}>
      <Center>
        {blogs.map((item: any, index: number) => {
          if (index === currentIndex) {
            return (
              <BlogCard data={item} key={item.slug} isInternal={isInternal} />
            );
          }
          return null;
        })}
      </Center>
      <HStack gap={2.5} mt={6} w="full" justify="center">
        {blogs.map((blog, index) => (
          <Box
            key={`indicator-${blog.id}`}
            bg={index === currentIndex ? "primary" : "divider"}
            w={2}
            h={2}
            borderRadius="50%"
          />
        ))}
      </HStack>
    </Box>
  );
};

export default Carousel;
