import { useState, useEffect } from "react";

const SUB_ROUTE = process.env.NEXT_PUBLIC_SUB_ROUTE;

const useOriginURL = () => {
  const [originURL, setOriginURL] = useState("");
  useEffect(() => {
    if (SUB_ROUTE) {
      setOriginURL(window.location.origin);
    }
  }, []);
  return originURL;
};
export default useOriginURL;
