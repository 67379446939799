type Mapping = {
  [x: string]: any;
};

export const largeImageMapping: Mapping = {
  hero: "/assets/images/hero-large.svg",
};

export const imageMapping: Mapping = {
  hero: "/assets/images/hero.svg",
  stake: "/assets/images/how/stake.webp",
  receive: "/assets/images/how/receive.webp",
  participate: "/assets/images/how/defi.webp",
  security: "/assets/images/security/security.webp",
  sdUtilEntryPoint:   {
    web: "/assets/images/sd_entry_char_web.svg",
    mobile: "/assets/images/sd_entry_char_mobile.svg",
  }
};

export const mobileImageMapping: Mapping = {
  hero: "/assets/images/hero-mobile.svg",
};

export const socialImageMapping: Mapping = {
  twitter: "/assets/images/blog/twitter.svg",
  telegram: "/assets/images/blog/telegram.svg",
  // discord: "/assets/images/blog/discord.svg",
  whatsapp: "/assets/images/blog/whatsapp.svg",
  facebook: "/assets/images/blog/facebook.svg",
  linkedin: "/assets/images/blog/linkedin.svg",
};