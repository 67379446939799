import { Box, Container, Flex, HStack, Text } from "@chakra-ui/react";

// Components
import NetworkCard from "../../NetworkCard";
import OptionCard from "./OptionCard";

// Constants
import { iconMapping, iconHoveredMapping } from "../../../constants/icons";
import { NavItemsType } from "../../../constants/types";
import { BlockChainInfoType } from "../../../hooks/useStaderTVL";

type Props = {
  data: NavItemsType;
  tvl: BlockChainInfoType | undefined;
};

const HeaderDrawer = ({ data, tvl }: Props) => {
  const { option_id: id, tag, title, subTitle, details } = data;

  return (
    <Container py={10}>
      <HStack justify="space-between" align="flex-start">
        <Box py={10}>
          {tag && (
            <Text textStyle="l12" color="accent">
              {tag}
            </Text>
          )}
          {title && (
            <Text mt={1} textStyle="h2">
              {title}
            </Text>
          )}
          {subTitle && (
            <Text mt={4} textStyle="p16" color="textSecondary">
              Earn more DeFi returns with liquid staking.
            </Text>
          )}
        </Box>
        {details.length > 0 && (
          <Flex flexWrap="wrap" gap={3} maxW={740}>
            {details.map(
              ({
                label,
                imgKey,
                primaryCTAText,
                primaryCTAUrl,
                secondaryCTAText,
                secondaryCTAUrl,
                link,
                isDisabled,
                tag,
                isDownloadable,
              }) =>
                id === "networks" ? (
                  <NetworkCard
                    key={label}
                    label={label}
                    icon={iconMapping[imgKey]}
                    section="header"
                    primaryCTAText={primaryCTAText as string}
                    primaryCTAUrl={primaryCTAUrl as string}
                    secondaryCTAText={secondaryCTAText as string}
                    secondaryCTAUrl={secondaryCTAUrl as string}
                    tag={tag}
                    tvl={
                      tvl &&
                      (tvl as any)[imgKey] &&
                      Intl.NumberFormat("en-US", {
                        notation: "compact",
                        style: "currency",
                        currency: "USD",
                      }).format((tvl as any)[imgKey]?.usd?.toFixed(0))
                    }
                    isHeader={true}
                  />
                ) : (
                  <OptionCard
                    key={label}
                    title={title}
                    label={label}
                    icon={iconMapping[imgKey]}
                    iconHovered={iconHoveredMapping[imgKey]}
                    link={link}
                    isDisabled={isDisabled}
                    tag={tag}
                    isDownloadable={isDownloadable}
                  />
                )
            )}
          </Flex>
        )}
      </HStack>
    </Container>
  );
};

export default HeaderDrawer;
