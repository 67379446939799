import { SyntheticEvent, useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Center,
  InputGroup,
  Input,
  InputRightElement,
  Show,
  Spinner,
  Image,
  Link,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  HStack,
  Flex,
  useMediaQuery,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { useSwipeable } from "react-swipeable";

// Components
import Section from "../Section";

// Utils
import { setSubscribedToEmail, validateEmail } from "../../utils/common";
import { trackEvent } from "../../utils/firebase";

// Services
import { subscribeToEmail } from "../../services/app";

// Constants
import { sheetMapping } from "./constants";
import { NavItemsType } from "../../constants/types";
import { iconMapping } from "../../constants/icons";
import { LP_SUBSCRIBE_EMAIL } from "../../constants/firebase";

import NetworkOptions from "./components/NetworkOptions";

type Props = {
  data: {
    section_details: {
      title: string;
      sub_title: string;
      section_id: string;
      cta_text: string;
    };
  };
  networks: NavItemsType["details"];
};

const Newsletter = ({ data, networks }: Props) => {
  const toast = useToast();

  const {
    section_details: { title, section_id, cta_text, sub_title },
  } = data;

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [selectedNetworks, setSelectedNetworks] = useState<{
    [x: string]: boolean;
  }>(() =>
    networks.reduce((acc, curr) => ({ ...acc, [curr.detailId]: true }), {})
  );

  const [isMobileOrTablet] = useMediaQuery("(max-width: 992px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (email) {
      const isValidEmail = validateEmail(email);

      if (isValidEmail) {
        setError("");
      } else {
        setError("Please enter correct email ID");
      }
    }
  }, [email]);

  const handleNetworkSelect = (id: string) => {
    setSelectedNetworks((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const selectedNetworkList = Object.keys(selectedNetworks).filter(
    (key) => selectedNetworks[key]
  );

  const unselectedNetworks = Object.keys(selectedNetworks).filter(
    (key) => !selectedNetworks[key]
  );

  const isNetworkPartiallySelected = unselectedNetworks.length > 0;

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    // trackEvent(SUBSCRIBE_EMAIL, { email_id: email });
    if (!validateEmail(email)) {
      setError("Please enter correct email ID");
      return;
    }

    if (selectedNetworkList.length === 0) {
      setError("No Network selected. Please select atleast one.");
      return;
    }

    setIsSubscribing(true);

    trackEvent(LP_SUBSCRIBE_EMAIL, { email_id: email });

    const formData: any = { email, networks: [] };

    selectedNetworkList.forEach((id) => {
      formData.networks.push(sheetMapping[id]);
    });

    const response = await subscribeToEmail(formData);

    if ((response as any).error) {
      toast({
        description: "Failed to subscribe",
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
      setIsSubscribing(false);

      return;
    }

    setSubscribedToEmail(true);

    toast({
      description: "Email subscribed successfully",
      status: "success",
      duration: 2000,
      position: "top",
      isClosable: true,
    });
    setIsSubscribing(false);
  };

  const handlers = useSwipeable({
    onSwipedDown: () => onClose(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <>
      <Box>
        <Section title={title} subTitle={sub_title} id={section_id}>
          <Center mt={5} flexDir="column">
            <form onSubmit={handleSubmit}>
              <InputGroup
                size="lg"
                mt="0.5rem"
                mb="0"
                w={{ base: "328px", lg: "656px" }}
              >
                <Input
                  bg="canvas"
                  border="none"
                  type="email"
                  required
                  placeholder="Email address"
                  value={email}
                  fontSize="1rem"
                  h={{ base: "56px", xl: "58px" }}
                  pl={{ base: 4, lg: 8 }}
                  borderRadius="10px"
                  onChange={(e) => setEmail(e.target.value)}
                  _placeholder={{ color: "#1425284D" }}
                />

                <InputRightElement h="58px" w="128px" mr="0.5rem">
                  <Show above="xl">
                    <Button type="submit" p="1.5rem" h={46}>
                      {isSubscribing ? (
                        <Spinner size="md" color="white" />
                      ) : (
                        <Text>{cta_text}</Text>
                      )}
                    </Button>
                  </Show>
                </InputRightElement>
              </InputGroup>
              {error && (
                <Text textStyle="p14" color="#e53e3e" mt="0.5rem">
                  {error}
                </Text>
              )}
              <Show below="xl">
                <Center>
                  <Button
                    w="152px"
                    type="submit"
                    mt="1.5rem"
                    h="40px"
                    p="10px 2.5rem"
                  >
                    {isSubscribing ? (
                      <Spinner size="md" color="white" />
                    ) : (
                      <Text>{cta_text}</Text>
                    )}
                  </Button>
                </Center>
              </Show>
            </form>
            <Center
              mt="1.5rem"
              flexDir={{ base: "column", xl: "row" }}
              alignItems="center"
              gap={1}
              fontSize={{ base: 12, lg: 16 }}
            >
              {isNetworkPartiallySelected ? (
                <Center flexDir="column" gap={4}>
                  <Text color="brand.textInactive" mr="6px">
                    {selectedNetworkList.length}{" "}
                    {selectedNetworkList.length === 1
                      ? "network "
                      : "networks "}
                    selected
                    <Link
                      ml={1}
                      variant="secondary"
                      onClick={onOpen}
                      cursor="pointer"
                      color="accent"
                    >
                      Change
                    </Link>
                  </Text>
                  <HStack align="center" gap={2}>
                    {networks
                      .filter(({ detailId }) =>
                        selectedNetworkList.includes(detailId)
                      )
                      .map(({ detailId, imgKey }) => (
                        <Image
                          key={`selected-${detailId}`}
                          boxSize={8}
                          src={iconMapping[imgKey]}
                          alt="label"
                        />
                      ))}
                  </HStack>
                </Center>
              ) : (
                <Flex
                  align="center"
                  flexDir={{ base: "column", lg: "row" }}
                  gap={2}
                >
                  <Image
                    display={{ base: "none", lg: "inherit" }}
                    src="/assets/images/check.svg"
                    alt="check"
                    h="24px"
                    w="25px"
                  />
                  <Text color="brand.textInactive">
                    You are subscribing to all our networks
                  </Text>
                  <Link
                    variant="secondary"
                    onClick={onOpen}
                    cursor="pointer"
                    color="accent"
                    fontSize={{ base: 10, lg: 14 }}
                    fontWeight={600}
                  >
                    Select networks
                  </Link>
                </Flex>
              )}
            </Center>
          </Center>
        </Section>
      </Box>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement={isMobileOrTablet ? "bottom" : "right"}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent maxW={773} bg="white">
          {!isMobileOrTablet && (
            <DrawerCloseButton left={10} top={10} boxShadow="none" />
          )}
          {isMobileOrTablet && (
            <Center {...handlers}>
              <Divider
                my={4}
                w="64px"
                borderRadius={4}
                borderWidth="4px"
                borderColor="divider"
              />
            </Center>
          )}
          <DrawerBody
            mt={{ base: "36px", lg: "104px" }}
            px={10}
            textAlign={{ base: "center", lg: "inherit" }}
          >
            <Text textStyle="h2">Select networks</Text>
            <NetworkOptions
              networks={networks}
              onSelectNetwork={(id) => handleNetworkSelect(id)}
              selectedNetworks={selectedNetworks}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Newsletter;
