import { BoxProps, Flex, Text, Link } from "@chakra-ui/react";
import { capitalizeFirstLetter } from "../../utils/common";
import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { IIndexable } from "../../types/common";
import useOriginURL from "../../hooks/useOriginURL";
import { tagLabelMap } from "../../constants/blogs";
const subRoute = process.env.NEXT_PUBLIC_SUB_ROUTE || "";

interface PathData {
  label: string;
  path: string;
}

interface BreadCrumProps extends BoxProps {
  currentPath?: string;
  isInternal?: boolean;
  data?: PathData[] | null;
}

const slugPath = "[slug]";
const tagPath = "[tag]";

const pathMap: IIndexable = {
  partners: "Our Partners",
  "about-stader": "About us",
  "terms-of-service": "Terms of service",
  blogs: "Our blogs",
  "staking-basics": "staking-basics",
};

const BreadCrumV2 = (props: BreadCrumProps) => {
  const originURL = useOriginURL();
  const router = useRouter();

  const getLabel = (path: string) => {
    const { query } = router;
    if (path === tagPath) {
      return tagLabelMap[query.tag as string] || pathMap.blogs;
    }
    if (path === slugPath) {
      return props.currentPath;
    }

    return pathMap[path] || capitalizeFirstLetter(path.replace(/-/g, " "));
  };

  const getPath = (path: string) => {
    const { query, pathname } = router;
    if (path === tagPath) {
      return `/?tag=${query.tag}`;
    }
    return `${pathname.split(path)[0]}${path}/`;
  };

  const data = useMemo(() => {
    if (props.data) {
      return props.data;
    }
    const { pathname } = router;
    const paths = pathname.split("/");
    if (subRoute !== "" && paths.length === 2) {
      return [
        {
          label: "Home",
          path: `${originURL}/`,
        },
        {
          label: pathMap.blogs,
          path: subRoute,
        },
      ];
    }

    if (paths[1] === "") {
      return [];
    }
    let data = paths.map((path: string, index: number) => {
      if (index === 0) {
        return {
          label: "Home",
          path: `${originURL}/`,
        };
      }
      return {
        label: getLabel(path),
        path: getPath(path),
      };
    });
    if (data.length === 4) {
      data = data.filter((item: any) => item.label !== pathMap.blogs);
    }
    return data;
  }, [router, props.data]);

  if (data.length === 0) return null;

  return (
    <Flex
      mt={{ base: "32px" }}
      mb={{ base: "64px" }}
      alignItems="center"
      mx={{ base: "16px" }}
      gap={{ base: "4px" }}
      {...props}
    >
      {data.map((item: PathData, index: number) => {
        return (
          <>
            {index !== 0 && (
              <Image
                src="/assets/images/chevron-right.svg"
                alt="right"
                width={24}
                height={24}
                key={`${item}${index}`}
              />
            )}
            {data.length - 1 === index ? (
              <Text
                cursor="normal"
                fontSize={{ base: "11px", lg: "16px" }}
                fontWeight={{ base: "500" }}
                lineHeight={{ base: "18px", lg: "24px" }}
                color="textPrimary"
                key={`${item}${index}`}
              >
                {item.label}
              </Text>
            ) : (
              <Link
                as={originURL && index === 0 ? Link : NextLink}
                href={item.path}
                passHref
                key={`${item}${index}`}
              >
                <Text
                  cursor="pointer"
                  fontSize={{ base: "11px", lg: "16px" }}
                  fontWeight={{ base: "500" }}
                  lineHeight={{ base: "18px", lg: "24px" }}
                  color="textSecondary"
                >
                  {item.label}
                </Text>
              </Link>
            )}
          </>
        );
      })}
    </Flex>
  );
};

export default BreadCrumV2;
