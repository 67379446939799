type Mapping = {
  [x: string]: any;
};

export const socialLinkMapping: Mapping = {
  twitter: "/assets/images/blog/twitter.svg",
  telegram: "/assets/images/blog/telegram.svg",
  discord: "/assets/images/blog/discord.svg",
  whatsapp: "/assets/images/blog/whatsapp.svg",
  facebook: "/assets/images/blog/facebook.svg",
  linkedin: "/assets/images/blog/linkedin.svg",
};

export const SUBSCRIPTIONS_URL = "https://common.staderlabs.com/subscriptions";
