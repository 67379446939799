import { IIndexable } from "../types/common";

export const LP_START_STAKING = "LP_Start_staking";
export const LP_SD_ENTRY_POINT = "LP_Sd_Entry_Point";
export const LP_NETWORKS = "LP_Networks";
export const LP_AUDIT_REPORTS = "LP_Audit_reports";
export const LP_SOCIAL = "LP_Social";
export const LP_FAQ = "LP_FAQ";
export const LP_GOVERNANCE = "LP_Governance";
export const LP_SUBSCRIBE_EMAIL = "LP_Subscribe_email";

export const LP_SUBSCRIBE_EMAIL_MODAL = "LP_Subscribe_email_modal";
export const LP_EMAIL_MODAL_OPEN = "LP_Email_modal_open";

export const LP_PARTNERS = "LP_Partners";
export const LP_PARTNERS_PAGE = "LP_Partners_page";

export const LP_DOCS_PAGE = "LP_Docs_page";
export const LP_DOCS_KNOW_MORE = "LP_Docs_know_more";
export const LP_DOCS_NETWORKS_CLICK = "LP_Docs_networks_click";

export const LP_ABOUT_PAGE = "LP_About_page";

export const LP_MAILING_LIST = "LP_Mailing_list";
export const LP_ANALYTICS = "LP_Analytics";
export const LP_DEVELOPERS = "LP_Developers";
export const LP_ABOUT = "LP_About";

export const LP_BLOGS_PAGE = "LP_Blogs_page";
export const LP_BLOGS_CLICK = "LP_Blogs_click";
export const LP_BLOGS_VIEW_MORE = "LP_Blogs_view_more";
export const LP_BLOGS_FILTER_CLICK = "LP_Blogs_filter_click";

// BLOGS
export const LP_VIEW_BANNER = "LP_View_banner";
export const LP_CLOSE_BANNER = "LP_Close_banner";
export const LP_BANNER_CTA_CLICK = "LP_Banner_cta_click";

export const LP_TOS_CLICK = "LP_TOS_click";

export const EVENT_MAPPINGS: IIndexable = {
  networks: LP_NETWORKS,
  governance: LP_GOVERNANCE,
  analytics: LP_ANALYTICS,
  developers: LP_DEVELOPERS,
  about: LP_ABOUT,
};
