import { useCallback, useEffect, useState } from "react";
import { useInterval, useIdle } from "react-use";

// Services
import { getStaderTVL } from "../services/app";

type InfoType = {
  exchangeRate: number;
  native: number;
  usd: number;
};

export type BlockChainInfoType = {
  bnb: InfoType;
  fantom: InfoType;
  hedera: InfoType;
  near: InfoType;
  polygon: InfoType;
  terra: InfoType;
  totalStakedLuna: number;
  totalStakedLunaInUst: number;
  totalUSD: number;
};

type TVLDataType = {
  tvl: BlockChainInfoType | undefined;
  isLoading: boolean;
};

const POLLING_INTERVAL = 10000;

export const useStaderTVL = (delay?: number, disablePolling = false) => {
  const isIdle = useIdle(150e3);

  const [data, setData] = useState<TVLDataType>({
    tvl: undefined,
    isLoading: true,
  });

  const fetchStaderTVL = useCallback(async () => {
    const response = await getStaderTVL();

    setData({
      tvl: response as BlockChainInfoType,
      isLoading: false,
    });
  }, []);

  useEffect(() => {
    fetchStaderTVL();
  }, [fetchStaderTVL]);

  useInterval(() => {
    if (!isIdle && !disablePolling) {
      fetchStaderTVL();
    }
  }, delay || POLLING_INTERVAL);

  return data;
};
