import { BlockChainInfoType } from "../hooks/useStaderTVL";

export const validateEmail = (email: string) => {
  const emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test(email);
};

export const STRAPI_URL = "https://cms-stage.staderlabs.click";

export const strapiRequestHeader = {
  headers: {
    Authorization:
      "Bearer e2b46fc3ee30e0dd08bae9b121d0e95deea9d7767a70a197288e8e275bd355a5da6bdfa79794e8ba88c82a0f9ef62d4ed8084f3cef981c5ee23d0a5830ab320d7be5572452f13ac3d063ca44fcd6f69de9255043718f639bf9256b00f856eb5f88db7c6e13ad93fe3e859e2f8fb86aa41015ad52e066600ef85a29fc4f8e2821",
  },
};

export async function fetchPageData(pageAPI: string) {
  const response = await fetch(`${STRAPI_URL}/api/${pageAPI}?populate=deep`, {
    ...strapiRequestHeader,
  });
  return await response.json();
}

export const isExternalLink = (link: string | null) => link?.startsWith("http");

export const getSectionDataByKey = (pageData: any, key: string) =>
  pageData?.sections.find((section: any) => {
    const sectionDetailskey =
      "section_details" in section ? "section_details" : "sectionDetails";

    return section[sectionDetailskey].section_id === key;
  });

export const prepareNavData = (
  _navData: any,
  tvl: BlockChainInfoType | undefined
) => {
  let networkWithoutOrder;
  const networkWithOrder = _navData?.navItems
    .find((item: any) => item.option_id === "networks")
    .details.filter((item: any) => +item.order > 0)
    .sort((a: any, b: any) => a.order - b.order);

  const _networkWithoutOrder =
    _navData?.navItems
      .find((item: any) => item.option_id === "networks")
      .details.filter((item: any) => !item.order) || [];

  if (tvl) {
    networkWithoutOrder = _networkWithoutOrder.sort(
      (a: any, b: any) =>
        (tvl as any)?.[b.dataKey]?.usd - (tvl as any)?.[a.dataKey]?.usd
    );
  } else {
    networkWithoutOrder = _networkWithoutOrder;
  }

  const networks = [
    ...(networkWithOrder || []),
    ...(networkWithoutOrder || []),
  ];

  const formattedNavItems = _navData?.navItems.map((item: any) => {
    if (item.option_id === "networks") {
      return { ...item, details: networks };
    }
    return item;
  });
  const navData = { ..._navData, navItems: formattedNavItems };

  return { navData, networks };
};

export const createCookieName = (x: string, prefix = "banner") => {
  const text = x.replaceAll(" ", "-");
  return `cookie-${prefix}-${text.slice(0, 20)}`;
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const trimText = (str: string, len: number = 15) => {
  if (str.length > len) {
    return str.substring(0, len) + "...";
  }
  return str;
};

export const setSubscribedToEmail = (isSubscribed: boolean) => {
  sessionStorage.setItem("isSubscribed", `${isSubscribed}`);
  window.dispatchEvent(new Event("storage"));
};

export const isSubscribedToEmail = () => {
  return sessionStorage.getItem("isSubscribed") === "true";
};
