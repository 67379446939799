import axios from "axios";

import { universeBaseURL } from "../utils/env";
import { SUBSCRIPTIONS_URL } from "../constants/links";

const NEXT_PUBLIC_BLOGS_KEY = process.env.NEXT_PUBLIC_BLOGS_KEY;

interface subscribeToEmailProps {
  email: string;
  networks: string[];
}
export const subscribeToEmail = async (data: subscribeToEmailProps) => {
  try {
    return axios.post(`${SUBSCRIPTIONS_URL}/save`, data);
  } catch (error) {
    // sentryErrorLog(error);
    return {
      error,
    };
  }
};

export const getBlogs = async () => {
  try {
    const response = await axios.get(
      `https://www.staderlabs.com/blog/ghost/api/content/posts/?key=${NEXT_PUBLIC_BLOGS_KEY}&page=1&limit=3&include=authors,tags`
    );

    return response as any;
  } catch (error) {
    // sentryErrorLog(error);
    return {
      error,
    };
  }
};

const genTags = (tags: string[]) => {
  return tags.map((item: string) => `&filter=tag:${item}`);
};
export const getRelatedBlogs = async (tags: string[]) => {
  try {
    const tagsString = genTags(tags).join("");
    const response = await axios.get(
      `https://www.staderlabs.com/blog/ghost/api/content/posts/?key=${NEXT_PUBLIC_BLOGS_KEY}&page=1&limit=4&include=authors,tags${tagsString}`
    );
    return response as any;
  } catch (error) {
    // sentryErrorLog(error);
    return {
      error,
    };
  }
};

export const getStaderTVL = async () => {
  const response = await fetch(`${universeBaseURL}/common/tvl`);
  const json = await response.json();

  return json;
};
