import { IIndexable } from "../types/common";
export const blogTagMapping: { [x: string]: string } = {
  sd: "sd",
  hedera: "hedera",
  polygon: "polygon",
  fantom: "fantom",
  "terra-2-0": "terra-2-0",
  near: "near",
  ethereum: "ethereum",
  bnbchain: "bnbchain",
  "staking-basics": "staking-basics",
};

export const tagLabelMap: IIndexable = {
  "terra-2-0": "Terra 2.0",
  "staking-basics": "Staking Basics",
  sd: "SD",
  polygon: "Polygon",
  bnbchain: "BNB",
  ethereum: "Ethereum",
  hedera: "Hedera",
  fantom: "Fantom",
  near: "Near",
};
