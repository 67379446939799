type Mapping = {
  [x: string]: string;
};

export const sheetMapping: Mapping = {
  polygon: "Polygon",
  binance: "BNB",
  near: "Near",
  ethereum: "Ethereum",
  hedera: "Hedera",
  terra: "Terra 2.0",
  fantom: "Fantom",
  solana: "Solana",
  sd: "SD",
};
