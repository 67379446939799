import { ReactNode } from "react";
import { Box, BoxProps, Container, Text } from "@chakra-ui/react";

interface Props extends BoxProps {
  title?: string;
  subTitle?: string;
  isH2?: boolean;
  children: ReactNode;
}

const Section = ({ title, subTitle, children, isH2, ...rest }: Props) => (
  <Box w="full" py={{ base: "64px", lg: "80px" }} {...rest}>
    <Container textAlign="center">
      {title && (
        <Text as={isH2 ? "h2" : "p"} textStyle={{ base: "h3", lg: "h1" }}>
          {title}
        </Text>
      )}

      {subTitle && (
        <Text
          mt={2}
          textStyle={{ base: "p12", lg: "p14" }}
          fontWeight="500 !important"
          color="textSecondary"
        >
          {subTitle}
        </Text>
      )}
      {children}
    </Container>
  </Box>
);

export default Section;
