import { useMemo } from "react";
import { Image, Text, Box } from "@chakra-ui/react";

import { LP_BLOGS_CLICK } from "../../../constants/firebase";
import { trackEvent } from "../../../utils/firebase";
import NextLink from "next/link";
import { tagLabelMap } from "../../../constants/blogs";

interface BlogCardProps {
  data: any;
  variant?: number;
  isInternal?: boolean;
}
const BlogCard = ({ data, variant = 1, isInternal }: BlogCardProps) => {
  const subRoute = isInternal ? "" : "/blogs";

  const postURL = useMemo(() => {
    const tag = data?.tags[0]?.slug;
    if (tag && tagLabelMap[tag]) {
      return `${subRoute}/${tag}/${data.slug}`;
    }
    return `${subRoute}/${data.slug}`;
  }, [data]);

  const handelPost = () => {
    trackEvent(LP_BLOGS_CLICK, {
      blog_slug: data.slug,
      type: "blogs_page",
    });
  };

  if (variant === 1) {
    return (
      <NextLink passHref rel="canonical" href={postURL} legacyBehavior={false}>
        <Box
          w={{ base: 328, lg: 370.67 }}
          h={{ base: 323, lg: 315.18 }}
          borderRadius={16}
          overflow="hidden"
          borderWidth="2px"
          borderColor="#E6F8EF"
          boxShadow="none"
          cursor="pointer"
          onClick={handelPost}
        >
          <Image
            h={{ base: 169, lg: 195 }}
            width="100%"
            src={data.feature_image}
            objectFit="cover"
            alt={data.title}
          />
          <Box
            display="flex"
            flexDir="column"
            justifyContent="space-between"
            textAlign="left"
            bg="#E6F8EF"
            p={6}
            borderRadius={16}
            borderTopRadius={0}
            color="textPrimary"
            height={{ base: 154, lg: 120.18 }}
          >
            <Text textStyle="h4" fontWeight={600} noOfLines={2} maxW={400}>
              {data.title}
            </Text>
            <Text mt={{ base: 2, lg: 12 }} textStyle="p14" fontWeight={500}>
              {data.reading_time} mins read / updated on{" "}
              {new Date(data.updated_at).toDateString()}
            </Text>
          </Box>
        </Box>
      </NextLink>
    );
  } else {
    return (
      <NextLink passHref rel="canonical" href={postURL} legacyBehavior={false}>
        <Box
          margin={{ base: "0px 16px", lg: 0 }}
          w={{ base: "100%", lg: 370.5 }}
          h={{ base: 83, lg: 356.79 }}
          borderRadius={{ base: 8, lg: 16 }}
          overflow="hidden"
          borderWidth={{ base: "1px", lg: "2px" }}
          borderColor="#E6F8EF"
          boxShadow="none"
          display="flex"
          flexDirection={{ base: "row", lg: "column" }}
          onClick={handelPost}
          cursor="pointer"
          bg="#E6F8EF"
        >
          <Image
            h={{ base: 83, lg: 195 }}
            w={{ base: 83, lg: "100%" }}
            src={data.feature_image}
            objectFit="cover"
            alt={data.title}
          />
          <Box
            display="flex"
            flexDir="column"
            justifyContent="space-between"
            textAlign="left"
            p={{ base: "12px", lg: "24px" }}
            color="textPrimary"
            height={{ base: "100%", lg: "137px" }}
            width="100%"
          >
            <Text
              textStyle={{ base: "h6", lg: "h4" }}
              fontWeight={600}
              noOfLines={2}
              maxW={400}
            >
              {data.title}
            </Text>
            <Text
              textStyle={{ base: "p10", lg: "p14" }}
              fontWeight={500}
              color="#3F6870"
            >
              {data.reading_time} mins read / updated on{" "}
              {new Date(data.updated_at).toDateString()}
            </Text>
          </Box>
        </Box>
      </NextLink>
    );
  }
};

export default BlogCard;
